@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

* {
  word-break: keep-all;
}

body {
  margin: 0;
}

html, body, #root {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.main-content {
  min-height: 90vh;
  position: relative;
  overflow: auto;
}

.min90 {
  min-height: 90vh;
}

.banner{
    background: white no-repeat center center;
    color: #ffffff;
    width: 100%;
    height: 30vh;
    margin-top: 1vh;
}

.section{
    padding-top: 2vh;
    padding-bottom: 2vh;
    background: white;
}